import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "gatsby";

/**
 * Displays a Section before the Footer with gray background.
 */
const FooterSection = ({ children, title, linkTo, linkText }) => {
  return (
    <div className="footer-section">
      <Container>
        <Row>
          <Col>
            <div className="footer-section__header">
              <h5 className="footer-section__title">{title}</h5>
              {linkTo && linkText && (
                <Link to={linkTo} className="footer-section__link">
                  <span>{linkText}</span>
                  <span className="icon icon-arrow-right" />
                </Link>
              )}
            </div>
            <Row className="footer-section__items">{children}</Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FooterSection;
