import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../layouts/Layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "../components/Pagination";
import Container from "react-bootstrap/Container";
import PostList from "../components/PostList";
import Card from "react-bootstrap/Card";
import CenteredImage from "../components/CenteredImage";
import FooterSection from "../components/FooterSection";

const Category = ({ pageContext, data, location }) => {
  const page = data.page;
  const relatedGuides = page.acf.related_guides;
  const guide = data.guide;
  const stringTranslations = pageContext.stringTranslations;

  const filteredPosts = data.catPosts.edges.filter((post) => {
    const categories = post.node.post_categories;

    return categories[0].term_id === page.term_id;
  })

  return (
    <Layout
      context={pageContext}
      className="category-page"
      useContainer={false}
      location={location}
      showProgressbar={false}
      disableHrefLang={pageContext.pageNumber !== 0}
    >
      <Container>
        <Row className="category-header">
          <Col lg={{ span: 5, offset: 1 }} className="category-header__content">
            <h1 dangerouslySetInnerHTML={{__html: page.cat_name}} />

            {page.category_description && (
              <div className="category-header__desc" dangerouslySetInnerHTML={{__html: page.category_description}} />
            )}

            {guide && (
              <ul className="category-header__list internal-links">
                  <li key={guide.post_title}>
                    <Link to={guide.path} dangerouslySetInnerHTML={{__html: guide.post_title}} />
                  </li>
              </ul>
            )}
          </Col>
          {page?.image?.localFile?.childImageSharp?.fluid?.srcSet && (
            <Col lg={5}>
              <img
                srcSet={page.image?.localFile?.childImageSharp?.fluid?.srcSet}
                src={page.image?.localFile?.childImageSharp?.fluid?.src}
                alt={page.cat_name}
              />
            </Col>
          )}
        </Row>

        <Row className="justify-content-center">
          <Col lg={6}>
            <PostList posts={filteredPosts} />
          </Col>
        </Row>

        <Row>
          <Col lg={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
            <Pagination {...pageContext} />
          </Col>
        </Row>
      </Container>

      {relatedGuides && relatedGuides.length > 0 && (
        <FooterSection title={stringTranslations["Related Guides"]}>
          {relatedGuides.map(guide => (
            <Col sm={12} md={6} lg={4} className="d-flex align-items-stretch">
              <Link to={guide.path} className="d-flex">
                <Card className="link related-guides__card">
                  {guide?.image?.url?.localFile?.childImageSharp?.fluid
                    ?.srcSet && (
                    <CenteredImage
                      alt={guide.post_title}
                      source={
                        guide.image.url.localFile.childImageSharp.fluid.srcSet
                      }
                    />
                  )}
                  <Card.Body>
                    <Card.Title>{guide.post_title}</Card.Title>
                    <Card.Text>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: guide.post_excerpt
                        }}
                      />
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </FooterSection>
      )}
    </Layout>
  );
};

export const query = graphql`
  query CategoryQuery(
    $wordpressID: Int!
    $languageCode: String!
    $skip: Int!
    $limit: Int!
  ) {
    page: wordpressCategory(term_id: { eq: $wordpressID }) {
      cat_name
      category_description
      path
      term_id
      image {
        localFile {
          childImageSharp {
            fluid(jpegQuality: 100, maxWidth: 980) {
              srcSet
              src
            }
          }
        }
      }
      language {
        root_path
      }
      translations {
        href
        locale
        locale_native_name
        locale_short
      }
      acf {
        related_guides {
            post_date
            post_title
            post_excerpt
            path
            image {
                url {
                    localFile {
                        childImageSharp {
                            fluid(jpegQuality: 70, maxWidth: 980) {
                                srcSet
                            }
                        }
                    }
                }
            }
        }
      }
    }
    catPosts: allWordpressPost(
      filter: {
        post_categories: { elemMatch: { cat_ID: { eq: $wordpressID } } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          post_date(locale: $languageCode, formatString: "MMMM D, YYYY")
          ...PostTeaserFields
        }
      }
    }
    guide: wordpressWpGuides(language: {language_code: {eq: $languageCode}}, post_categories: {elemMatch: {cat_ID: {eq: $wordpressID}}}) {
        post_title
        path
    }
  }
`;

export default Category;
