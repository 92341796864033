import React from "react";
import { Link } from "gatsby";
import {Helmet} from "react-helmet";

const Pagination = ({ nextPagePath, previousPagePath, pages, stringTranslations }) => {
  return (
    <div className="pagination">
      <Helmet>
        { previousPagePath && <link rel="prev" href={previousPagePath} />}
        { nextPagePath && <link rel="next" href={nextPagePath} />}
      </Helmet>
      <div className="nav previous">
        { previousPagePath ? (
          <Link
            to={previousPagePath}
            className="link"
          >
            <div className="nav-content">
              <span className="icon icon-arrow-left" />
              <span className="text">{ stringTranslations["Prev"] }</span>
            </div>
          </Link>
        ) : (
          <span className="link disabled">
            <div className="nav-content">
              <span className="icon icon-arrow-left" />
              <span className="text">{ stringTranslations["Prev"] }</span>
            </div>
          </span>
        )}
      </div>
      <div className="pages">
        <ul>
          {pages.map(page => (
            <li className={page.active} key={page.number}>
              <Link to={page.path} className="page">
                {page.number}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="nav next">
        { nextPagePath ? (
          <Link to={nextPagePath} className="link">
            <div className="nav-content">
              <span className="text">{ stringTranslations["Next"] }</span>
              <span className="icon icon-arrow-right" />
            </div>
          </Link>
        ) : (
          <span className="link disabled">
            <div className="nav-content">
              <span className="text">{ stringTranslations["Next"] }</span>
              <span className="icon icon-arrow-right" />
            </div>
          </span>
        )}
      </div>
    </div>
  );
};

export default Pagination;
